<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Moyen de transport"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-card
                title="Moyen de transport"
                no-body
              >
                Moyen de transport
              </b-card>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between flex-wrap">
            <!-- sorting  -->
            <b-form-group
              label="Trier"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="2"
              label-for="sortBySelect"
              class="mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Filtrer"
              label-cols-sm="2"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          :current-page="currentPage"
          :items="moyensTransport"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template #cell(disponible)="data">
            <b-badge
              pill
              :variant="`${resolveUserStatusVariant(data.item.disponible ? 'Disponible' :'Non disponible')}`"
              class="text-capitalize"
            >
              {{ data.item.disponible ? 'Disponible' :'Non disponible' }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <!-- <span @click="$router.push({ name: 'espace-affreteur.details', params: { code: data.item.code} })">
              <feather-icon icon="EyeIcon" />
              <span class="align-middle text-primary ml-50" />
            </span> -->
            <b-dropdown
              variant="link"
              no-caret
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                v-b-modal.validation
                @click="getMoyenTransport(data.item)"
              >
                <feather-icon
                  icon="CheckSquareIcon"
                  class="text-success"
                />

                <span class="align-middle text-success ml-50"> Modifier</span>

              </b-dropdown-item>
              <!-- <b-dropdown-item
                v-b-modal.suspendre
                @click.prevent="supprimerMoyenTransport(data.item.id )"
              >
                <feather-icon
                  icon="XSquareIcon"
                  class="text-danger"
                />
                <span class="align-middle text-danger ml-50"> Supprimer</span>
              </b-dropdown-item> -->
            </b-dropdown>
          </template>

        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-cols="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="moyensTransport.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-col>

    <!-- form modal -->
    <b-modal
      id="validation"
      ref="my-modal"
      cancel-title="Annuler"
      ok-title="Modifier"
      title="Modification d'un moyen de transport"
      modal-class="modal-success"
      ok-variant="success"
      button-size="sm"
      size="lg"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleOkForm"
    >
      <validation-observer
        ref="modifyTransportMeansRules"
        tag="form"
      >
        <b-form @submit.prevent="handleSubmitModalForm">
          <b-row class="mt-2">
            <b-col md="6">
              <b-form-group
                label="Catégorie de camion *"
                label-for="mc-first-name"
              >
                <v-select
                  id="pays"
                  v-model="transportMeans.cat_camion_slug"
                  label="name"
                  :options="categoryCamions"
                  :reduce="(categoryCamions) => categoryCamions.slug"
                  placeholder="Catégorie de camion"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Type de camion *"
                label-for="type_camion"
              >
                <validation-provider
                  #default="{ errors }"
                  name="type camion"
                  rules="required"
                >
                  <v-select
                    id="type_camion"
                    v-model="transportMeans.type_camion_id"
                    label="libelle"
                    size="sm"
                    :options="typeCamions"
                    :reduce="(typeCamions) => typeCamions.id"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Type de camion"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="N° Chassis"
                label-for="num_chassis"
              >
                <validation-provider
                  #default="{ errors }"
                  name="numéro chassis"
                  rules="required"
                >
                  <b-form-input
                    id="num_chassis"
                    v-model="transportMeans.num_chassis"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Numéro chassis"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Immatriculation *"
                label-for="immatriculation"
              >

                <validation-provider
                  #default="{ errors }"
                  name="numéro d'immatriculation"
                  rules="required"
                >
                  <b-form-input
                    id="immatriculation"
                    v-model="transportMeans.num_immatriculation"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Numéro d'immatriculation"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Nombre d'essieux *"
                label-for="essieux"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombre d'essieux"
                  rules="required"
                >
                  <b-form-input
                    id="essieux"
                    v-model.number="transportMeans.nombre_essieux"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Nombre d'essieux"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Libellé *"
                label-for="marque"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Libellé"
                  rules="required"
                >
                  <b-form-input
                    id="marque"
                    v-model="transportMeans.libelle"
                    size="sm"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Libellé"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Disponibilité *"
                label-for="disponible"
              />
              <validation-provider
                name="disponible"
                rules="required"
              >
                <b-form-checkbox v-model="transportMeans.disponible">
                  {{ transportMeans.disponible? 'Oui':'Non' }}
                </b-form-checkbox>
              </validation-provider>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- form modal -->
    <b-modal
      id="suspendre"
      ref="my-modal"
      cancel-title="Non"
      ok-title="Oui"
      title="Suspension d'offre de frêt"
      modal-class="modal-danger"
      ok-variant="danger"
      button-size="sm"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="handleOk"
    >
      <form @submit.prevent="handleSubmitModal">
        <h6>Etes-vous sûr de vouloir suspendre ce moyen de transport?</h6>
      </form>
    </b-modal>

  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  required,
} from '@validations'

import {
  ref, onMounted, reactive, watch,
} from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BFormGroup,
  BForm,
  BFormSelect,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox,
  BButton,
  BBadge,
  BCardBody,
  BFormInput,
  BSpinner,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import useOffres from '@/services/affreteur/offresService'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
// eslint-disable-next-line import/no-cycle
import useTypeCamions from '@/services/admin/typeCamionService'
// eslint-disable-next-line import/no-cycle
import useCategoryCamions from '@/services/admin/categoryCamionService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormCheckbox,
    BForm,
    BButton,
    BBadge,
    BCardBody,
    BSpinner,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  setup() {
    const {
      terminerOffre, offres,
    } = useOffres()
    const {
      getTransporteursMeans, moyensTransport, updateTransportMeans, deleteTransportMeans, offreSuccess, loader,
    } = useSocieteTransporteurs()

    const {
      getCategoryCamions, categoryCamions,
    } = useCategoryCamions()

    const {
      typeCamions, getTypesCamionByCategoryCamionBySlug, typeCamion, getTypeCamion,
    } = useTypeCamions()

    const offreCode = ref('')
    const dataValidate = reactive({ statut: '' })
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const infoModal = {
      id: 'info-modal',
      title: '',
      content: '',
    }

    const fields = [
      {
        key: 'index', label: 'N°',
      },
      { key: 'type_camion.libelle', label: 'Type de camion', sortable: true },
      { key: 'libelle', label: 'Désignation camion', sortable: true },
      { key: 'num_immatriculation', label: 'Immatriculation', sortable: true },
      { key: 'num_chassis', label: 'N° Chassis', sortable: true },
      { key: 'disponible', label: 'Etat' },
      { key: 'actions' },
    ]
    const transportMeans = reactive({
      cat_camion_slug: '',
      type_camion_id: '',
      libelle: '',
      slug: '',
      description: '',
      num_chassis: '',
      num_immatriculation: '',
      disponible: '',
      nombre_essieux: '',

    })

    // Obtenir un moyen de transport par son slug
    const getMoyenTransport = async item => {
      await getTypeCamion(item.type_camion.slug)
      transportMeans.cat_camion_slug = typeCamion.value.categorie_camion.slug
      transportMeans.libelle = item.libelle
      transportMeans.disponible = !!item.disponible
      transportMeans.nombre_essieux = item.nombre_essieux
      transportMeans.num_immatriculation = item.num_immatriculation
      transportMeans.num_chassis = item.num_chassis
      transportMeans.slug = item.slug
      transportMeans.type_camion_id = item.type_camion.id
    }
    /* eslint-disable global-require */
    const items = moyensTransport.value

    onMounted(async () => {
      await getTransporteursMeans()
      await getCategoryCamions()

      // Set the initial number of items
      totalRows.value = items.length
      console.clear()
    })

    const modifyTransportMeans = async () => {
      updateTransportMeans(transportMeans)
      console.clear()
    }

    const currentType = async data => {
      typeCamion.value = ''
      await getTypesCamionByCategoryCamionBySlug(data)
      console.clear()
    }

    const acceptOffer = async (code, data) => {
      await terminerOffre(code, data)
    }
    const supprimerMoyenTransport = async id => {
      await deleteTransportMeans(id)
    }
    const getOffreCode = (code, stateChange) => {
      offreCode.value = code
      dataValidate.statut = stateChange
    }

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    watch(() => _.cloneDeep(transportMeans.cat_camion_slug), value => {
      currentType(value)
    })
    const resolveUserStatusVariant = status => {
      if (status === 'Non disponible') return 'danger'
      if (status === 'Disponible') return 'success'
      return 'primary'
    }
    const formatter = value => value.toUpperCase()
    return {
      getMoyenTransport,
      transportMeans,
      supprimerMoyenTransport,
      modifyTransportMeans,
      required,
      categoryCamions,
      typeCamions,
      resolveUserStatusVariant,
      moyensTransport,
      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      infoModal,
      fields,
      items,

      onFiltered,
      offreSuccess,
      formatter,
      loader,
      offres,
      getOffreCode,
      offreCode,
      acceptOffer,
      dataValidate,
    }
  },

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  methods: {

    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModal()
    },
    handleOkForm(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmitModalForm()
    },
    handleSubmitModal() {
      // Exit when the form isn't valid
      this.supprimerMoyenTransport(this.id)
      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
    async handleSubmitModalForm() {
      // Exit when the form isn't valid
      this.$refs.modifyTransportMeansRules.validate().then(async success => {
        await this.modifyTransportMeans()

        if (success) {
          // Hide the modal manually
          if (this.offreSuccess) {
            this.$nextTick(() => {
              this.$refs['my-modal'].toggle('#toggle-btn')
            })
          }
        }
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
